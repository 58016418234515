import { commonServerPromise } from "@/utils/http.js";

/**
 * 获取场所配置信息
 * @param {*} params
 * @returns Promise {<pending>}
 */
export const getPlaceConfig = (params) => {
  return commonServerPromise({
    method: "get",
    url: "/api/small_business_config/get_place_config",
    params: params,
  });
};

/**
 * 修改场所配置信息
 * @param {*} params
 * @returns Promise {<pending>}
 */
export const updatePlaceConfig = (params) => {
  return commonServerPromise({
    method: "POST",
    url: "/api/small_business_config/update_place_config",
    params: params,
  });
};
