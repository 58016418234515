<template>
  <div class="relay-main">
    <!-- 配置-START -->
    <div class="relay-form">
      <van-cell-group inset>
        <van-field
          v-for="(item, index) in pageData.formName"
          :key="index"
          rows="1"
          autosize
          type="textarea"
          :clearable="true"
          v-model="pageData.formData[item]"
          :label="pageData.formLabel[item]"
          :placeholder="pageData.formPlaceholder[item]"
          autocomplete="off"
        />
      </van-cell-group>
    </div>
    <!-- 配置-START -->
    <!-- 按钮-START -->
    <div class="relay-button">
      <van-cell-group
        inset
        style="display: flex; justify-content: space-around"
      >
        <van-button
          type="default"
          :disabled="pageData.buttonDisable"
          @click="restore_config()"
          >{{ pageData.buttonName.restore }}</van-button
        >
        <van-button
          type="default"
          :disabled="pageData.buttonDisable"
          @click="reset_config()"
          >{{ pageData.buttonName.reset }}</van-button
        >
        <van-button
          type="default"
          :disabled="pageData.buttonDisable"
          @click="save_config()"
          >{{ pageData.buttonName.save }}</van-button
        >
      </van-cell-group>
    </div>
    <!-- 按钮-END -->
  </div>
</template>
<script setup>
import { ref, reactive } from "vue";
import store from "@/store/index.js";
import { Dialog, Toast } from "vant";
import { getPlaceConfig, updatePlaceConfig } from "@/api/relay";

/* 变量初始化-START */
const mac = store.getters.mac;
const placeid = store.getters.placeid;
const sign = store.getters.sign;
/* 遮罩层 */
const toast = ref(null);
/* 初始化标准 */
const cacheData = ref({
  live_name_0: "",
  live_address_0: "",
  live_name_1: "",
  live_address_1: "",
  live_name_2: "",
  live_address_2: "",
  live_name_3: "",
  live_address_3: "",
  live_name_4: "",
  live_address_4: "",
  live_name_5: "",
  live_address_5: "",
  live_name_6: "",
  live_address_6: "",
  live_name_7: "",
  live_address_7: "",
});
/* 页面动态数据 */
const pageData = reactive({
  buttonDisable: true,
  buttonName: {
    restore: "恢复初始设置",
    reset: "重置",
    save: "保存",
  },
  formName: [
    "live_name_0",
    "live_address_0",
    "live_name_1",
    "live_address_1",
    "live_name_2",
    "live_address_2",
    "live_name_3",
    "live_address_3",
    "live_name_4",
    "live_address_4",
    "live_name_5",
    "live_address_5",
    "live_name_6",
    "live_address_6",
    "live_name_7",
    "live_address_7",
  ],
  formLabel: {
    /* 输入框Label */
    live_name_0: "主信号名称",
    live_address_0: "主信号地址",
    live_name_1: "子信号名称1",
    live_address_1: "子信号地址1",
    live_name_2: "子信号名称2",
    live_address_2: "子信号地址2",
    live_name_3: "子信号名称3",
    live_address_3: "子信号地址3",
    live_name_4: "子信号名称4",
    live_address_4: "子信号地址4",
    live_name_5: "子信号名称5",
    live_address_5: "子信号地址5",
    live_name_6: "子信号名称6",
    live_address_6: "子信号地址6",
    live_name_7: "子信号名称7",
    live_address_7: "子信号地址7",
  },
  formPlaceholder: {
    /* 输入框placeholder */
    live_name_0: "",
    live_address_0: "",
    live_name_1: "",
    live_address_1: "",
    live_name_2: "",
    live_address_2: "",
    live_name_3: "",
    live_address_3: "",
    live_name_4: "",
    live_address_4: "",
    live_name_5: "",
    live_address_5: "",
    live_name_6: "",
    live_address_6: "",
    live_name_7: "",
    live_address_7: "",
  },
  formData: {
    /* 输入框model */
    live_name_0: "",
    live_address_0: "",
    live_name_1: "",
    live_address_1: "",
    live_name_2: "",
    live_address_2: "",
    live_name_3: "",
    live_address_3: "",
    live_name_4: "",
    live_address_4: "",
    live_name_5: "",
    live_address_5: "",
    live_name_6: "",
    live_address_6: "",
    live_name_7: "",
    live_address_7: "",
  },
});
/* 变量初始化-END */

/* 函数初始化-START */
/* 初始化表单输入项 */
const init_form_value = function (obj) {
  if (!obj) {
    return false;
  }
  pageData.formData = obj;
  cacheData.value = JSON.parse(JSON.stringify(obj));
};
/* 获取配置 */
const query_config = function () {
  toast.value = Toast.loading({
    duration: 0,
    forbidClick: true,
  });
  const params = {
    mac: mac,
    place_id: placeid,
    sign: sign,
  };
  getPlaceConfig(params)
    .then((result) => {
      toast.value.clear();
      console.log(result);
      if (result.data && result.data.code && result.data.code === 1) {
        pageData.buttonDisable = false;
        const res_data = result.data.data ? result.data.data : "";
        init_form_value(res_data);
      } else {
        Dialog({ message: "获取场所配置信息失败，禁止操作。" });
      }
    })
    .catch((err) => {
      toast.value.clear();
      Dialog({ message: "获取场所配置信息出错，禁止操作。" + err });
    });
};
/* 修改配置 */
const update_config = function (params) {
  updatePlaceConfig(params)
    .then((result) => {
      toast.value.clear();
      if (result.data && result.data.code && result.data.code === 1) {
        Dialog({ message: "修改场所配置信息成功！" });
      } else {
        Dialog({ message: "修改场所配置信息失败！" });
      }
    })
    .catch((err) => {
      toast.value.clear();
      Dialog({ message: "修改场所配置信息出错！" + err });
    });
};
/* 表单数据处理 */
const relay_box_form = function () {
  let ret_params = {
    live_name_0: pageData.formData.live_name_0,
    live_address_0: pageData.formData.live_address_0,
    live_name_1: pageData.formData.live_name_1,
    live_address_1: pageData.formData.live_address_1,
    live_name_2: pageData.formData.live_name_2,
    live_address_2: pageData.formData.live_address_2,
    live_name_3: pageData.formData.live_name_3,
    live_address_3: pageData.formData.live_address_3,
    live_name_4: pageData.formData.live_name_4,
    live_address_4: pageData.formData.live_address_4,
    live_name_5: pageData.formData.live_name_5,
    live_address_5: pageData.formData.live_address_5,
    live_name_6: pageData.formData.live_name_6,
    live_address_6: pageData.formData.live_address_6,
    live_name_7: pageData.formData.live_name_7,
    live_address_7: pageData.formData.live_address_7,
  };
  return JSON.stringify(ret_params);
};
/* 获取表单数据 */
const get_operate_data = function (type) {
  toast.value = Toast.loading({
    duration: 0,
    forbidClick: true,
  });
  let params = {
    mac: mac,
    place_id: placeid,
    sign: sign,
    type: type,
  };
  params.live_info = relay_box_form();
  update_config(params);
};
/* 恢复初始设置 */
const restore_config = function () {
  const type = "Reset";
  get_operate_data(type);
};
/* 重置 */
const reset_config = function () {
  const reset_data = JSON.parse(JSON.stringify(cacheData.value));
  pageData.formData = reset_data;
};
/* 保存 */
const save_config = function () {
  const type = "Update";
  get_operate_data(type);
};
/* 函数初始化-END */

/* 初始化 */
if (!mac || !placeid || !sign) {
  Dialog({ message: "身份信息不完整，禁止操作。" });
} else {
  query_config();
}
</script>
<style lang="scss"></style>
