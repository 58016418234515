import axios from "./request.js";

/**
 * 封装请求
 * @param：{options.method}     String      请求类型，必填
 * @param：{options.url}        String      请求地址，必填
 * @param：{options.params}     Object      请求参数，非必填
 * @returns：Promise {<pending>}
 **/
export const commonServer = (options) => {
  if (options.method == "get") {
    const arr = [];
    Object.keys(options.params).forEach(function (item) {
      arr.push(item + "=" + options.params[item]);
    });
    if (arr.length > 0) {
      options.url += "?" + arr.join("&");
    }
    options.params = {};
  }
  return axios({
    method: options.method,
    url: options.url,
    headers: {
      "Content-Type": "application/json",
    },
    data: options.params,
  })
    .then((res) => res.data)
    .catch((err) => err);
};

/**
 * 封装请求  Promise，适用await
 * @param：{options.method}     String      请求类型，必填
 * @param：{options.url}        String      请求地址，必填
 * @param：{options.params}     Object      请求参数，非必填
 * @returns：Promise {<pending>}
 **/
export const commonServerPromise = (options) => {
  if (options.method == "get") {
    const arr = [];
    Object.keys(options.params).forEach(function (item) {
      arr.push(item + "=" + options.params[item]);
    });
    if (arr.length > 0) {
      options.url += "?" + arr.join("&");
    }
    options.params = {};
  }
  return new Promise((resolve, reject) => {
    axios({
      method: options.method,
      url: options.url,
      headers: {
        "Content-Type": "application/json",
      },
      data: options.params,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
